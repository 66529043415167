import React from 'react'
import ModalKioskLanguage from '../../ModalKioskLanguage'

export const MenuBar = ( {setStep} ) => {


   // const redirection = () => {
   //    navigate(`/kiosk/ecommers/branchshoffices/${business_code}`)
   // }
   return (
      <>
         {/* <div className='mx-1'>
            <div className='d-flex justify-content-around position-absolute bottom-0 start-50 translate-middle-x
                           bg-dark  rounded-4 border-3  border border-white  w-100'>
             
              <ModalKioskLanguage></ModalKioskLanguage>

               <button className='btn btn-outline-light border-0 ' onClick={() => setStep(11)}>
                  <i class="uil uil-shop"></i>
                  <small className='d-block fw-bold'>
                     Sucursal
                  </small>
               </button>
               <button className='btn btn-outline-light border-0 ' onClick={() => setStep(2)}>
                  <i class="uil uil-square-shape"></i>
                  <small className='d-block fw-bold'>
                     Menú
                  </small>
               </button>
               <button className='btn btn-outline-light border-0 ' onClick={() => setStep(10)}>
                  <i class="uil uil-receipt-alt"></i>
                  <small className='d-block fw-bold'>
                     Historial
                  </small>
               </button>
               <button className='btn btn-outline-light border-0' onClick={() => setStep(12)}>
                  <i class="uil uil-user-circle"></i>
                  <small className='d-block fw-bold'>
                     Perfil
                  </small>
               </button>
            </div>
         </div> */}


      </>
   )
}
