import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class NotificationsFetchAPI {

    //Function to create a notification
    static async createNotification(data, token) {
        const res = await server(`/notification`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: data,
        });
        return res
    }

    //Function to get notifications types by branchoffice 
    static async getNotificationsTypesByBranchoffice(branchoffice_id, token) {
        const res = await server(`/branch/${branchoffice_id}/notification_type`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get notifications by branchoffice and table
    static async getNotifications(branchoffice_code, business_code, table_code, minDate, maxDate, token) {
        let url = `/notification?business_code=${business_code}`

        //We add the branchoffice code
        if (branchoffice_code !== undefined) {
            url += `&branchoffice_code=${branchoffice_code}`
        }

        //We add the table code
        if (table_code !== undefined) {
            url += `&table_code=${table_code}`
        }

        //We add the min date
        if (minDate !== undefined) {
            url += `&minDate=${minDate}`
        }

        //We add the max date
        if (maxDate !== undefined) {
            url += `&maxDate=${maxDate}`
        }
        //Get the notifications
        const res = await server(url, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }
}