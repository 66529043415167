import React, { useState } from 'react'
function KeyPad({ displayNumber, setDisplayNumber, placeHolderInput }) {
    const [isButtonFocusedId, setButtonFocusId] = useState(null);   
    //the state is declared in father component (displayNumber, setDisplayNumber)

    const keyValue1 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
    const keyValue2 = ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"]
    const keyValue3 = ["a", "s", "d", "f", "g", "h", "j", "k", "l", "@"]
    const keyValue4 = ["z", "x", "c", "v", "b", "n", "m", ".", "-", "_"]

    const handleNumberClick = (number) => {
        setDisplayNumber((prevDisplayNumber) => prevDisplayNumber + number);
    };

    const handleClearClick = () => {
        setDisplayNumber('');
    };



    return (
        <>
            <div className='keyboard-main'>
                <div className='keyboard-pad d-flex flex-wrap'>
                    <input type="text"
                        className='keypadPrimary-input_1 form-control'
                        placeholder={placeHolderInput === undefined ? '0000' : placeHolderInput} value={displayNumber} readOnly
                        maxLength="4"
                    />


                    <div className='row d-flex justify-content-center'>
                        <div className='d-flex justify-content-center my-1'>

                            {keyValue1.map((key, index) => (
                                <div className='position-relative '>
                                    <button className=
                                        {` key_1 rounded kiosk-key-letter`}                                       
                                        onClick={() => handleNumberClick(key)}>{key}</button>                              
                                </div>
                            ))}
                        </div>
                        <div className='d-flex justify-content-center  d-block my-1'>
                            {keyValue2.map((key, index) => (
                                <div className=' position-relative'>
                                    <button className=
                                        {` key_1 rounded kiosk-key-letter`}
                                       onClick={() => handleNumberClick(key)}>{key}</button>
                                </div>
                            ))}
                        </div>
                        <div className='d-flex justify-content-center  d-block my-1'>
                            {keyValue3.map((key, index) => (
                                <div className=' position-relative'>
                                    <button className=
                                        {` key_1 rounded kiosk-key-letter`}
                                     onClick={() => handleNumberClick(key)}>{key}</button>                        
                                </div>
                            ))}
                        </div>
                        <div className='d-flex justify-content-center  d-block my-1'>
                            {keyValue4.map((key, index) => (
                                <div className=' position-relative'>
                                    <button className=
                                        {` key_1 rounded kiosk-key-letter`}
                                       onClick={() => handleNumberClick(key)}>{key}</button>
                                </div>
                            ))}
                        </div>

                        <div className='d-flex justify-content-center  d-block'>
                            <div className='position-relative '>
                                <button className={` key_1 keySpaceDelete rounded btn kiosk-key-letter px-5`}                  
                                    onClick={() => handleNumberClick(" ")}> 
                                    <i className="uil uil-space-key px-5 mx-5"></i>
                                </button>                          
                            </div>
                            <div className=' position-relative'>
                                <button className={` key_1 keySpaceDelete rounded btn kiosk-key-letter`}                
                                    onClick={() => handleClearClick()}>Borrar</button>                       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KeyPad