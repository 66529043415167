import React from 'react'

function Header({ classContainer, classImg }) {
   let logo = localStorage.getItem('logo');

   if(localStorage.getItem !== undefined || localStorage.getItem !== null || localStorage.getItem !== "" ){
      let businessLogo=JSON.parse(localStorage.getItem('businessConfig'))
     if (businessLogo !== undefined || businessLogo !== null) {
      //logo = businessLogo.logo
   }
     
   }

   return (
      <>
         <div className={classContainer}>
            <img src={logo} 
            className={classImg + " rounded mt-1"} alt="Logo SmartPay"
            loading='lazy' />
         </div>         
      </>
   )
}

export default Header