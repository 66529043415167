
import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class StatusMenu {
    
    //Function to get the business suppliers 
    static async getContentMenu( token, machines_id){
        const res = await server(`/content_status?machine_id=${machines_id}`, {
            method: "get",
            headers: { 'x-access-token': token }
        });
        return res
    }
    
}

