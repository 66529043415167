import React from 'react'
import Modal from 'react-bootstrap/Modal';

//imgs
import logo from '../../assets/img/logos/visa.svg'

const Payments = ({ modalShow, setModalShow, payments, invoiceTotal, selectedPayment, setSelectedPayment }) => {
   let title_categori = 'Ensalada552'
   


   const selectPayment = (data) => {
      setSelectedPayment(data);
      console.log(data);
   }
   return (
      <>
         <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="lg"
            centered
         >

            <Modal.Body className='p-0'>
               {/* 
               <section className='ecomerce-container-product-0'>
                  <div className='ecomerce-container-1'>
                     <h4 className='fw-bold text-center'>
                        <i class="uil uil-bill me-1"></i>
                        Área de Pago
                     </h4>
                     <div>
                        Hola
                     </div>

                     <div className='ecomerce-container-section-0 border border-5 border-light bg-light pb-5'>

                     </div>
                     <div>
                        <div >Close</div>
                     </div>
                  </div>

               </section> */}
               <section className='ecomerce-container-product-0'>
                  <div className='ecomerce-container-1 position-relative'>
                     <div className='position-relative'>

                        <div className=' p-2 w-100 d-flex justify-content-between align-items-center  '>
                           <button className='btn btn-header-ecommerce-product  btn-sm rounded-3' onClick={() => setModalShow(false)}>
                              <i className="uil uil-angle-left-b icon-header-ecommerce"></i>
                           </button>
                           <h4 className='fw-bold text-center'>
                              <i class="uil uil-bill me-1"></i>
                              Área de Pago
                           </h4>
                           {/* <button className='btn btn-header-ecommerce-product   btn-sm rounded-3'>
                              <i class="uil uil-info icon-header-ecommerce"></i>
                           </button> */}
                           <span className='px-3'></span>
                        </div>

                     </div>
                     <div className='p-1'>
                        <div className='bg-header-payments py-3 text-center'>
                           <h2>$ {invoiceTotal} </h2>
                           <small className='fw-light'>Total a pagar </small>
                        </div>
                     </div>
                     <div className='ecomerce-container-info mx-2'>
                        <h4 className='ecomerce-container-info-title fw-bold mt-2'>Métodos de pago</h4>
                     </div>
                     <div className='ecomerce-container-section-0 border border-1 border-primary bg-light pb-5 '>

                        {payments.map((payment) => (
                           <div className='my-1 mx-1 list-pre-orders-inputs' onChange={() => selectPayment(payment)}>
                              <input
                                 className="list-group-item-check-pays "
                                 type="radio"
                                 name="option_payments" id={'payment' + payment.payment_id}
                              />

                              <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-1 shadow-sm"
                                 htmlFor={'payment' + payment.payment_id}
                              >
                                 <div className="d-flex align-items-center gap-3 py-1 rounded" >
                                    <img src={payment.img}
                                       alt="Product" width="80" height="80" className="rounded border flex-shrink-0  img-payment" />
                                    <div className="d-flex gap-2 justify-content-around py-2">
                                       <span className="mb-3 text-wrap">
                                          {payment.payment}
                                       </span>
                                    </div>
                                 </div>
                                 <span class="badge bg-secondary-subtle text-secondary-emphasis rounded-pill bottom-0 end-0 position-absolute m-1">

                                 </span>
                              </label>
                           </div>
                        ))}




                        {/* <div className='p-1'>
                        <div className='d-flex justify-content-between align-items-center'>
                           <span className='item-value-product'>
                              $ 18.00
                           </span>
                           <div className='d-flex justify-content-around gap-1 align-items-center'>
                              <span><i class="uil uil-minus-circle item-count-product"></i></span>
                              <span className=' item-count-product'>0</span>
                              <span><i class="uil uil-plus-circle item-count-product"></i></span>
                           </div>
                        </div>
                        <button className='btn btn-add-product w-100' onClick={props.onHide} > Agregar al carrito</button>
                      */}
                     </div>
                     <div className='py-2'></div>
                  </div>
               </section>
            </Modal.Body>


         </Modal>



      </>
   )
}

export default Payments