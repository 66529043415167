import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class ClientsFetchAPI {

    static async getClientsByBusiness(business_id, token) {
        const res = await server(`/client/${business_id}/business_clients`, {
            method: "get",
            headers: {'x-access-token': token },
        });
        return res
    }

    static async getClientsById(client_id, token) {
        const res = await server(`/client/${client_id}`, {
            method: "get",
            headers: {'x-access-token': token },
        });
        return res
    }

    static async createClient(data, token) {
        const res = await server(`/client`, {
            method: "post",
            data: data,
            headers: {'x-access-token': token },
        });
        return res
    }

 
 
}