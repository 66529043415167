import React, { useState } from 'react'
//Component
import Promotions from './components/Promotions';
import { RenderIf } from '../../components/common/utils/RenderIf';
import CartButton from './components/CartButton';
import { MenuBar } from './components/MenuBar/MenuBar';

function Categories({ setCategorieSelect, background, categories, updateData, nextStep, prevStep, skipToStep3, selectCategorie, promotions, getSegmentsByPromotion, setAtivity, setStep, shoppingCart, parthners }) {

  return (
    <>
      <main className='kiosk-delivery'>
        <section className='kiosk-categories' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(1)}>
          <div className='carousel-container position-relative bg-primary'>
            <Promotions  promotions={promotions} getSegmentsByPromotion={getSegmentsByPromotion} />
          </div>
          <div className='position-absolute  w-100'>
            <div className='d-flex justify-content-end bg-transparent align-items-center me-1 mt-3'>
              {/* <Header
                classContainer={"kiosk-categories-header scale-up-horizontal-left"}
                classImg={"kiosk-header-img-categories"} /> */}
              <RenderIf isTrue={shoppingCart.length > 0}>
                <CartButton setStep={setStep} shoppingCart={shoppingCart}></CartButton>
              </RenderIf>
            </div>
          </div>
          <div className='kiosk-delivery_section categories position-absolute w-100'>
            <div className='kiosk-container_section_lista' >
              <div className='kiosk-lista scroll-fast position-relative'>
                {categories.map((categorie, index) => (
                  <>
                    <button className={` btn kiosk-lista_items position-relative  bounce-left`}

                      type='button' key={index}
                      autoFocus={index === 0}
                      onClick={() => (selectCategorie(categorie.category_id, true), setCategorieSelect(categorie))}>

                      <div className="card kiosk-card-item shadow-lg h-100"
                        id={categorie.category_id} value={categorie.category_id}
                      >
                        <div className='card-img-top bg-light h-100'>
                          <img src={categorie.img} className='rounded-4 ' width="100%" alt="..." loading='lazy' />
                        </div>

                        <div className="card-body">
                          <span className='kiosk-text-categories d-block text-center text-truncate'>
                            {categorie.category_name}
                          </span>
                        </div>
                      </div>
                    </button>
                  </>
                ))}
              </div>
            </div>
          </div>


          <div className='d-flex position-absolute top-0 start-0 container-button'>
            <button
              type="button"
              onClick={() => setStep(0)}
              className={`m-0 btn-lg  shadow-lg border-0 button-ft_1 position-relative`}
              title='Regresar'
            >
              <svg xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                className=""
                id="angle-left-b">
                <path d="m8.5 12.8 5.7 5.6c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-4.9-5 4.9-5c.4-.4.4-1 0-1.4-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3l-5.7 5.6c-.4.5-.4 1.1 0 1.6 0-.1 0-.1 0 0z">
                </path></svg>
            </button>
          </div>

          {/* <div className=' bottom-0 start-50 translate-middle-x position-absolute'>
  <h2 className='text-center '>
    <span className="kiosk-container_section_title badge rounded-4  bg-warning text-dark">
      Categorias</span>
  </h2>
</div> */}

          <MenuBar setStep={setStep} ></MenuBar>
        </section>
      </main>




    </>
  );
}
export default Categories