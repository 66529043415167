import React, { useState } from 'react'
//Component
import Header from "./components/Header"
import ModalLoader from './ModelLoader';
//Imgs
import { Modal } from 'react-bootstrap';
import { DiscountPosFetchAPI } from '../../api/PaymentPost/Discount';
import ErrorAlert from '../../components/Alerts/ErrorAlert';


function ModalAplyDiscount({ icon, background, showModalAplyDiscount, setModalAplyDiscount, setShoppingCart, shoppingCart, setEventHandler, setModalDiscount, invoiceTotal,
   invoiceTotalItbms,
   invoiceSubtotal,
   invoiceTotalDiscount, setAtivity }) {

   const [productChecked, setProductChecked] = useState([])
   let token = localStorage.getItem('x-access-machine-token')
   let instervalDicount = 0

   const [showModalLoader, setShowModalLoader] = useState(false)
   const [isButtonFocusedId, setButtonFocusId] = useState(null);

   const handleButtonFocus = (buttonId) => {
      setButtonFocusId(buttonId);
   };

   const handleButtonBlur = () => {
      setButtonFocusId(null);
   };

   //filter checkbox in state true and save in state array
   const getChecked = (event) => {
      let dataProduct = JSON.parse(event.target.value)
      dataProduct.index = event.target.dataset.index;

      if (event.target.checked === true) {
         setProductChecked([...productChecked, dataProduct])
      } else if (event.target.checked === false) {
         for (let i = 0; i < productChecked.length; i++) {
            if (productChecked[i].index === event.target.dataset.index) {
               productChecked.splice(i, 1)
            }
         }
      }
   }

   //create orders temporal of discount
   let type = 0
   const createTempDiscount = async () => {
      setAtivity(2);
      type = 0
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'))

      let jsonDiscountTemp = {
         "client_name": "0",
         "cupon_code": "0",
         "is_cupon": false,
         "localizador": "0",
         "client_phone": "0",
         "dgi_qr": "0",
         "Orden": productChecked,
         "amount_order_product": productChecked.length,
         "Descuento": "0",
         "Total": invoiceTotal,
         "itbms": invoiceTotalItbms,
         "subtotal": invoiceSubtotal,
         "machine_id": machine.id,
         "branchoffice_id": branchoffice.id,
         "tipoOrden": "comer aqui",
         "paymethod": 0,
         "is_discount_notification": true,
         "user": 0
      }

      try {
         const response = await DiscountPosFetchAPI.createTempDiscount(jsonDiscountTemp, token)

         if (response.data.message === 'Tempral order created') {
            getOrdersTempDiscount()
         }
      } catch (err) {
         console.log(err);
         console.log("ya hay una temporal");
         type = 1
         getOrdersTempDiscount()

      }
   }

   // get order temporal discount
   let countGetDiscount = 0 // count to limit get orders
   const getOrdersTempDiscount = async () => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'))
      let business = JSON.parse(localStorage.getItem('business'))
      clearInterval(instervalDicount)

      try {
         const response = await DiscountPosFetchAPI.getTempDiscount(machine.code, business.code, branchoffice.code, token)
         setAtivity(1);
         let code = response.data.order.code
         let message = ""
         let title = ""
         let icon = ""

         if (type === 0) {
            if (response.data.order.status_id === 1) {
               if (countGetDiscount <= 13) {
                  console.log(countGetDiscount);
                  instervalDicount = setInterval(getOrdersTempDiscount, 5000)
                  countGetDiscount++
               } else {
                  message = "Tiempo de espera agotado"
                  title = "Advertencia"
                  icon = "warning"
                  updateOrderDiscount(code, 5, message, title, icon) //state 5 delete order dicount
                  setProductChecked([])
                  setModalAplyDiscount(false)
                  setModalDiscount(false)
                  setShowModalLoader(false)
                  countGetDiscount = 0
               }
            } else if (response.data.order.status_id === 8) {//order temporal is cancel
               message = "Orden temporal cancelada"
               title = "Operacion denegada"
               icon = "error"
               updateOrderDiscount(code, 5, message, title, icon, type)
               setProductChecked([])
               setModalAplyDiscount(false)
               setModalDiscount(false)
               setShowModalLoader(false)

            } else if (response.data.order.status_id === 9) {//order temporal is success
               message = "Descuento aplicado con exito."
               title = "Operacion exitosa"
               icon = "success"

               let tempOrderDiscount = response.data.order.groups
               //format json 
               for (let i = 0; i < tempOrderDiscount.length; i++) {

                  for (let s = 0; s < tempOrderDiscount[i].segments.length; s++) {
                     tempOrderDiscount[i].segments[s].item_id = tempOrderDiscount[i].segments[s].item.item_id
                     tempOrderDiscount[i].segments[s].segment_name = tempOrderDiscount[i].segments[s].name
                     tempOrderDiscount[i].segments[s].name = tempOrderDiscount[i].segments[s].item.name
                     tempOrderDiscount[i].segments[s].cantidadItem = tempOrderDiscount[i].segments[s].item.amount
                     tempOrderDiscount[i].segments[s].description = tempOrderDiscount[i].segments[s].item.description
                     tempOrderDiscount[i].segments[s].img = tempOrderDiscount[i].segments[s].item.img

                  }
                  tempOrderDiscount[i].DetalleSegmentos = tempOrderDiscount[i].segments
                  tempOrderDiscount[i].cantidadItem = tempOrderDiscount[i].amount
                  tempOrderDiscount[i].descuento = tempOrderDiscount[i].discount

               }

               //filter product only with discount
               let arrayTempProduct = []
               for (let s = 0; s < shoppingCart.length; s++) {
                  for (let t = 0; t < tempOrderDiscount.length; t++) {
                     if (tempOrderDiscount[t].group_id === shoppingCart[s].group_id) {
                        console.log(" entre identificando id de grupo");
                        if (tempOrderDiscount[t].segments_string === shoppingCart[s].segments_string) {
                           console.log("Es igual");
                           if (shoppingCart[s].descuento > 0) {
                              arrayTempProduct.push(shoppingCart[s])
                           } else {
                              arrayTempProduct.push(tempOrderDiscount[t])
                           }
                        }
                     }
                  }
                  //------------------------
               };

               //filter array  not discount
               let responseArray = obtenerValoresDiferentes(shoppingCart, productChecked)
               setShoppingCart(arrayTempProduct.concat(responseArray));

               setEventHandler(1)
               updateOrderDiscount(code, 3, message, title, icon);
               //We clean the state of the products checked 
               setProductChecked([])
               setModalAplyDiscount(false)
               setModalDiscount(false)
               setShowModalLoader(false)
            }
         } else if (type === 1) {
            console.log("consulte la temporal remanente");
            updateOrderDiscount(code, 5, message, title, icon)
         }
      } catch (err) {
         console.log(err);
      }
   }

   //Attay filter shopingcart
   function obtenerValoresDiferentes(arr1, arr2) {
      var ArrayFilter = arr1.filter(function (elemento) {
         return !arr2.some(function (e) {
            return e.group_id === elemento.group_id && e.segments_string === elemento.segments_string;
         });
      });

      return ArrayFilter;
   }



   //update orders temporal discount
   const updateOrderDiscount = async (code, status, message, title, icon) => {

      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))

      let jsonUpdate = {
         "order_code": code,
         "business_code": business.code,
         "branchoffice_code": branchoffice.code,
         "status": status
      }
      try {
         const response = await DiscountPosFetchAPI.updateOrderTemp(jsonUpdate, token)
         console.log(response);
         if (type === 0) {
            ErrorAlert(message, title, icon)
         } else if (type === 1) {
            console.log("llame crear nuevamente despues de borrar la ya existente");
            createTempDiscount()
         }

      } catch (err) {
         console.log(err);
      }

   }

   //event to notification discount
   const aplyDiscount = async () => {
      let message = ""
      let title = ""
      let icon = ""


      if (productChecked.length > 0) {

         createTempDiscount()
         // let title = 'Solicitud de decuento enviada'
         // let message = 'Espere un momento el supervisor esta aprobando el descuento....<br/> <br/>'
         //LoadingAlert(title, message);

         setShowModalLoader(true);
      } else {

         message = "Debe seleccionar un producto."
         title = "No hay productos seleccionados."
         icon = "error"
         ErrorAlert(message, title, icon)

      }
   }
   return (
      <>

         <Modal
            show={showModalAplyDiscount}
            fullscreen
            onHide={() => {
               setModalAplyDiscount(false);
               setProductChecked([])
            }}>
            <Modal.Body className='m-0 p-0'>
               <section className='kiosk-delivery' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(1)}>
                  <Header
                     classContainer={"kiosk-delivery_header m-0 p-0"}
                     classImg={"kiosk-delivery_header_img"}>
                  </Header>
                  <h2 className='kiosk-delivery_title mx-2 text-center'>
                     Selecciona el/
                     los producto(s) para el descuento
                  </h2>
                  <div className='kiosk-delivery_section scale-up-top'>
                     <div className=''>
                        <div className="row mx-2">
                           {/* Validatopn Shoppint Cart Empty  */}
                           {
                              !shoppingCart.length
                                 ? <><p className='fw-bold '>No hay productos. </p></>
                                 : shoppingCart.map((product, index) => (

                                    <div className="col-12 col-md-12 col-lg-6 mt-5">
                                       {/* <label className="list-group-item align-items-center py-2 d-flex gap-3 border rounded ps-2">
                                 <input className="form-check-input flex-shrink-0" type="checkbox" value={JSON.stringify(product)} data-index={index++} defaultChecked={false} name="checked1" onChange={(e) => (getChecked(e))} />
                                 <span className="form-checked-content">
                                    <div className="d-flex text-muted px-xs-2" >
                                       <img className="me-2 rounded bg-secondary m-1" src={product.img} width="42" height="42" />

                                       <div className="d-flex justify-content-start mb-0 lh-sm pt-3">
                                          <span className="text-dark fw-bold title-product ">{product.name}</span>
                                          <label className="fw-bold" >$ {product.price.toFixed(2)}</label>
                                       </div>
                                    </div>
                                 </span>
                              </label> */}

                                       <input
                                          className="list-group-item-check "
                                          type="checkbox" value={JSON.stringify(product)}
                                          data-index={index} defaultChecked={false}
                                          id={product.name + "discount" + index}
                                          name="checked1" onChange={(e) => (getChecked(e))}
                                          disabled={product.descuento > 0 ? true : false}
                                       />

                                       <label className={`list-group-item py-1 px-1 h-100 position-relative      
                                          ${isButtonFocusedId === product.name ? 'focused' : ''}`}
                                          onFocus={() => handleButtonFocus(product.name)}
                                          onBlur={handleButtonBlur}
                                          htmlFor={product.name + "discount" + index}
                                       >
                                          <div className="d-flex gap-3 py-3 rounded " >
                                             <img src={product.img}
                                                alt="Product" className="rounded flex-shrink-0 kiosk-img-discount" />
                                             <div className="d-flex gap-2 w-75 justify-content-around align-item-center pt-3">
                                                <div>
                                                   <h6 className="mb-0 fw-bold kiosk-text-discount">Producto : {product.name}</h6>
                                                   <p className="mb-0 opacity-75  kiosk-text-discount">Precio: {product.price.toFixed(2)}</p>
                                                </div>
                                             </div>
                                          </div>

                                          <div className="position-absolute postition-icon-list">
                                             {isButtonFocusedId === product.name && <img src={icon} alt="Hand" className='icon-focus' />}
                                          </div>

                                       </label>
                                    </div>
                                 ))}
                        </div>

                     </div>
                  </div>

               </section>




               {/* <button className='btn btn-primary btn-vending-product  rounded' onClick={() => (setModalAplyDiscount(false), setProductChecked([]))} >
                        <i className="uil uil-arrow-left" ></i>
                        Regresar
                    </button>

                    <button className='btn btn-success btn-vending-product  rounded' onClick={() => (aplyDiscount())} >
                    <i className="uil uil-percentage"></i>
                         Solicitar descuento
                    </button> */}

               {/* 
            <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>

               <button
                  type="button"
                 
                  className="m-0 btn-lg  shadow-lg border-0 button-ft_1" title='Regresar' >
                  <svg xmlns="http://www.w3.org/2000/svg"
                     data-name="Layer 1"
                     viewBox="0 0 24 24"
                     className=""
                     id="left-arrow">
                     <path
                        d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>
               </button>     
            </div> */}
               <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>
                  <button
                     onClick={() => {
                        setModalAplyDiscount(false);
                        setProductChecked([])
                     }}
                     className={`btn btn-danger kiosk-subtitle-md  
          kiosk-border-4 py-3 m-3 w-100 position-relative  rounded-4`}


                  >Cancelar

                  </button>


                  <button
                     className={`btn btn-success kiosk-subtitle-md  
          kiosk-border-4 py-3 m-3 w-100 position-relative rounded-4`}

                     onClick={() => (aplyDiscount())}


                  > Solicitar descuento


                  </button>

               </div>
            </Modal.Body>
         </Modal>

         <ModalLoader background={background} showModalLoader={showModalLoader} setShowModalLoader={setShowModalLoader}></ModalLoader>

      </>
   )
} export default ModalAplyDiscount