//React components 
import React from 'react'
//Components 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Header } from '../../components/common/Header/Header';


function ModalWelcomePage({ showModalWelcomePage, setShowModalWelcomePage, background }) {

    let logo = localStorage.getItem('logo')
    return (
        <>
            <Modal show={showModalWelcomePage} fullscreen>

                <Modal.Body>
                    <section className='ecomerce-container-0' style={{ backgroundImage: `url(${background})` }}>

                        <header className='text-center fw-bold d-flex justify-content-center  align-items-center shadow-sm'>
                            <div>
                                <img src={logo}
                                    alt="Logo Bussiness" className='img-ecommerce-header rounded-3' />
                            </div>
                        </header>


                        <div class="position-absolute top-50 start-50 translate-middle ">

                            <div className="bg-light border-sp d-flex align-items-center justify-content-center  p-3">

                                <div className='mt-5'>
                                    <h2 className='text-center'>Bienvenido a la <br></br> habitacion: </h2>
                                    <h1 className='text-center txtRoom mt-3'>777</h1>
                                </div>

                            </div>

                            <div className=" d-flex align-items-center justify-content-center mt-5 ">
                                <button type="button" class="btn btn-outline-secondary" onClick={() => setShowModalWelcomePage(false)}> <h2><i class="uil uil-bed"></i> Servicio a la habitacion</h2></button>
                            </div>
                        </div>


                    </section>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default ModalWelcomePage