import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
const LoginUser = (props) => {
   return (
      <>
         <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
         >

            <Modal.Body>
               {/* <div className='header-profile-banner-login position-relative'>
                  <img className='header-profile-img'
                     src="https://images.unsplash.com/photo-1466978913421-dad2ebd01d17?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />

                  <div className='header-profile-text-banner position-absolute '>
                     <div className=' text-center mt-5 text-white fw-bold'>
                        ¡Ingresa ahora!
                     </div>
                  </div> 
               </div> */}
               <div className='position-absolute '>
                  <div className='fw-bold badge rounded-2 text-bg-success'>
                     <i class="uil uil-chat-bubble-user"></i>  Usuario
                  </div>
               </div>

               <h2 className={'kiosk-delivery_title text-center text-success '}>
                  Inicio de Sesión
               </h2>
               <div className='d-flex align-items-center justify-content-center'>
                  <div className="text-dark rounded-4 position-relative text-wrap bg-white   p-2">
                     <div className='d-flex'>
                        <Form>
                           <Form.Group className="mb-3 kiosk-text-card" controlId="exampleForm.ControlInput1">
                              <span className="fw-bold">Ingresa tu email </span>
                              <Form.Control className='kiosk-text-card border' type="text" placeholder="email@example.com" />
                           </Form.Group>
                           <Form.Group className="mb-3 kiosk-text-card " controlId="exampleForm.ControlInput1">
                              <span className="fw-bold">Ingresa la contraseña</span>
                              <Form.Control className='kiosk-text-card border' type="password" placeholder="*********" />
                           </Form.Group>
                        </Form>
                     </div>
                     <button className={`btn btn-success rounded btn-sm kiosk-subtitle-md position-relative px-4 w-100`}>
                        Ingresar
                     </button>
                  </div>
               </div>

            </Modal.Body>
            {/* <Modal.Footer>
               <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
         </Modal>
      </>
   )
}

export default LoginUser