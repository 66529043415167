import Swal from 'sweetalert2'; 

function SuccessAlert(message) {
    return (
        //We show a loading alert 
        Swal.fire({
            icon: 'success',
            title: 'Realizado!', //mensaje en pantalla
            text: message,
            showConfirmButton: false,
            timer: 1500
        })
    )
}

export default SuccessAlert