import React, { useState } from 'react'
//Component
import Header from "./components/Header"
import ModalKioskLanguage from './ModalKioskLanguage';
import { RenderIf } from '../../components/common/utils/RenderIf';

//Icons
import refund from './assets/img/refund.png'


function KioskDashboard({ data, icon, background, updateData, nextStep, skipToStep3, setCheckDeliveryOrder, statusLanguage, setStatusLanguage, setAtivity, parthners, setStep, preOrder, preOrderInvoice, branchoffice, redirection, name, getOrdersSuccessTable }) {

  const [isButtonFocusedId, setButtonFocusId] = useState(null);

  const handleButtonFocus = (buttonId) => {
    setButtonFocusId(buttonId);
  };

  const handleButtonBlur = () => {
    setButtonFocusId(null);
  };


  return (
    <>
      <section className='kiosk-delivery'
        style={{ backgroundImage: `url(${background})` }}
        onClick={() => setAtivity(1)}>
        {/* Headaer */}

        <div className='d-flex justify-content-center'>
          <Header
            classContainer={"scale-up-horizontal-left"}
            classImg={"kiosk-delivery_header_img"}>
          </Header>
        </div>


        {/* Content */}
        <div className=''>
          <div className='kiosk-delivery_section'>
            <h2 className='kiosk-delivery_title  text-center p-2'>
              ¡Bienvenido! <br />
              ¿Qué deseas hacer?
            </h2>
            <RenderIf isTrue={preOrderInvoice === true} >
              <div className='d-flex align-items-center justify-content-center'>
                <div className="d-flex align-items-center border-history shadow-sm  p-3 bg-white" onClick={() => setStep(5)}>
                  <div className="flex-shrink-0 p-2">
                    <img src={refund} alt="En progreso" width="100" height="100" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className='mt-2 me-2'>
                      <h3 className='mt-1 kiosk-text-card'><strong > {preOrder.client_name} </strong ></h3>
                      <h5 className="mb-0 text-muted kiosk-text-card">Orden abierta #{preOrder.transaction_code} </h5>
                      <h5 className='mt-1 kiosk-text-card'>Total:<strong > {preOrder.total.toFixed(2)} $</strong ></h5>
                    </div>
                  </div>
                </div>
              </div>
            </RenderIf>

            <div className='kiosk-delivery_btn'>
              <div className='kiosk-delivery_btns'>
                <button
                  id='comer_aqui'
                  onClick={() => (getOrdersSuccessTable(), setCheckDeliveryOrder("comer aqui"), setStep(10))}
                  className={`btn  btn-outline-primary  btn-card-delivery  btn-lg m-2 
                  shadow border border-2 position-relative`}>

                  <i class="uil uil-clipboard-alt btn-img-delivery"></i>
                  <span className='kiosk-text-delivery d-block '>
                    Administrar
                  </span>
                </button>
              
                <button
                  id='para_llevar'
                  onClick={() => (setCheckDeliveryOrder("para llevar"), setStep(2))}
                  className={`btn  btn-outline-primary  btn-card-delivery  btn-lg m-2 
                  shadow border border-2 position-relative`}>
                  <i className="uil uil-shopping-bag btn-img-delivery "></i>
                  <span className='kiosk-text-delivery d-block'>
                    Menu
                  </span>
                </button>
              </div>
            </div>

            <RenderIf isTrue={parthners.length > 0}>
              <div className='d-flex position-absolute start-0 bottom-0 container-button'>
                <button
                  type="button"
                  onClick={() => setStep(1)}
                  className={`m-0 btn-lg  shadow-lg border-0 button-ft_1 position-relative`}
                  title='Regresar' >
                  <svg xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    className=""
                    id="left-arrow">
                    <path
                      d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>
                </button>
              </div>
            </RenderIf>
          </div>
        </div>

        {/* Footer */}
        {/* <Footer></Footer> */}
        <div className="d-flex justify-content-center">
          <ModalKioskLanguage
            icon={icon}
            background={background}
            statusLanguage={statusLanguage}
            setStatusLanguage={setStatusLanguage}
            setAtivity={setAtivity} >
          </ModalKioskLanguage>
        </div>

        <div className="d-flex row justify-content-center alignt-items-center mt-2">
          <div className=" badge  bg-light text-dark   border-primary border rounded-4  position-relative text-wrap  p-3 w-75">
            <div className=''>
              <span className='text-primary fw-bold d-block  p-0 text-uppercase kiosk-subtitle-md mt-2'>
                <i class="uil uil-user"></i> {name} </span>
            </div>
            <span className='text-primary fw-bold d-block  p-0 text-uppercase kiosk-subtitle-md'>
              <i class="uil uil-shop"></i> {branchoffice.name}  </span>
            <button className={`btn btn-primary rounded btn-sm kiosk-subtitle-md position-relative mt-1`} onClick={() => redirection()}>
              Cambiar sucursal
            </button>
          </div>
        </div>
      </section>


    </>
  )
}

export default KioskDashboard