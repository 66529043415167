import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { BarMenu } from '../common/BarMenu/BarMenu'
import { Header } from '../common/Header/Header'
import initData from '../Home/initData';
//CSS
import './menu.css';
import { ModalProduct } from './ModalProduct';

//IMG
import all_product from '../img/icons/all.png'

export const Menu = () => {
   const navigate = useNavigate()
   const { business_code, branchoffice_code, machine_code_table } = useParams();
   let locationMe = useLocation();

   //STATES MENU
   const [categories, setCategories] = useState([]);
   const [promotions, setPromotions] = useState([]);
   const [groups, setGroups] = useState([]);
   const [allGroups, setAllGroups] = useState([]);
   const [categorieNow, setCategorieNow] = useState({});
   const [handlerEvent, setHandlerEvent] = useState(0);
   const [selectedGroup, setSelectedGroup] = useState({});
   const [segmentsGroup, setSegmentsGroup] = useState([]);
   const [shoppingcart, setShoppingcart] = useState([]);


   //----------------------------auth params localstorage---------------------------------------------------
   let machine = "";
   let business = "";
   let branchoffice = "";
   let logo = ""
   let params = false
   let token = localStorage.getItem("x-access-machine-token")
   if (localStorage.getItem('logo') != null || localStorage.getItem('logo') != undefined || localStorage.getItem('logo') != "") {
      logo = localStorage.getItem('logo')
   }
   if (localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("x-access-machine-token") == undefined || localStorage.getItem("branchoffice") == "" || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("machine") == "" || localStorage.getItem("machine") == undefined) {

   } else {
      params = true
      branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      //setBranchoffice(JSON.parse(localStorage.getItem('branchoffice')))
      machine = JSON.parse(localStorage.getItem('machine'));
      business = JSON.parse(localStorage.getItem("business"));
   }
   //---------------------------------------------------------------------------------------------------------



   const [modalShow, setModalShow] = React.useState(false);
   //----------------------Data general-----------------------\

   //consolidates data into corresponding statements
   const dataconsolidation = (generalData) => {
      if(generalData == 'error'){
         if(localStorage.getItem('table') != undefined &&localStorage.getItem('table') != '' && localStorage.getItem('table') != null){
            let dataTable = JSON.parse(localStorage.getItem('table'))
            let codeTable= dataTable.table_code
            navigate(`/branch/${business_code}/${codeTable}`)
         }else{
            navigate(`/branch/${business_code}`)
         }
         }
      let data = generalData

      //all groups--------------------------------------------------------
      //section categories----------------------------------------------
      let dataGroups = data.groups
      let groups_all = dataGroups.filter(({ status }) => status === 1);
      setAllGroups(groups_all)
      //------------------------------------------------------------------

      //section categories------------------------------------------------
      let dataCategories = data.category
      let typeCodeCategories = 'P'
      let categories_info = dataCategories.filter(({ code }) => code === typeCodeCategories);
      setCategories(categories_info)

      //---------------------------------------------------------------------
      //selected categorie---------------------------------------------------

      let selectCategorie = localStorage.getItem('selectedCategorie')
      if (selectCategorie != null || selectCategorie != undefined || selectCategorie != '') {
         let categorie = JSON.parse(selectCategorie)
         setCategorieNow(categorie)
         setGroups(categorie.groups);
      } else {
         navigate(`/home/${business_code}/${branchoffice_code}/${machine_code_table}`)
      }


      //--------------------------DATA SHOPPINGCART-------------------------------------------
      if (localStorage.getItem('shoppingCart') == null || localStorage.getItem('shoppingCart') == undefined || localStorage.getItem('shoppingCart') == "") {
         setShoppingcart([])
      } else {
         let datashopingcart = JSON.parse(localStorage.getItem('shoppingCart'))
         setShoppingcart(datashopingcart)
      }

   }

   //========================================================================================
   const selectCategorie = (categorie) => {
      localStorage.setItem('selectedCategorie', JSON.stringify(categorie))
      setCategorieNow(categorie)
      setGroups(categorie.groups);
      setHandlerEvent(1)
   }

   //=========================================================================================
   const selectProduct = (data) => {
      setModalShow(true)
      setSelectedGroup(data)
      if (data.segments) {
         setSegmentsGroup(data.segments)
      } else {
         setSegmentsGroup([])
      }

   }

   //============================================================================================
   const handlerInit = async () => {
      try {
         let response = await initData()
         dataconsolidation(response)
      } catch (err) {

         if(localStorage.getItem('table') != undefined &&localStorage.getItem('table') != '' && localStorage.getItem('table') != null){
            let dataTable = JSON.parse(localStorage.getItem('table'))
            let codeTable= dataTable.table_code
            navigate(`/branch/${business_code}/${codeTable}`)
         }else{
            navigate(`/branch/${business_code}`)
         }
         console.log(err);
      }
   }

   useEffect(() => {
      handlerInit()
   }, [])

   useEffect(() => {
      setHandlerEvent(0)
   }, [handlerEvent])

   return (
      <section className='ecomerce-container-0'>
         <div className='ecomerce-container-1 position-relative'>
            <Header></Header>
            <div>
               <div className="d-flex gap-1 overflow-auto py-2 ps-1">

                  {categories.map((categorie) => (

                     <div className='list-pre-orders-inputs ' key={categorie.category_id}>
                        <input
                           className="list-group-item-check "
                           type="radio"
                           id={categorie.category_id}
                           name="checked1"
                           checked={categorieNow.category_id == categorie.category_id ? true : false}
                           onChange={() => selectCategorie(categorie)} />
                        <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-0 categories-menu shadow-sm"
                           htmlFor={categorie.category_id}
                        >
                           <div className="d-flex flex-block   gap-1 py-1 rounded" >
                              <img src={categorie.img}
                                 alt="Product" className="rounded  flex-shrink-0  img-categories-menu" />
                           </div>
                           <div className="d-block px-1">
                              <span className={`text-categories-menu 
                                       text-center ${categorie.category_name.length > 10 ? 'text-categories-menu-slider ' : 'text-categories-menu '}`}>
                                 {categorie.category_name}
                              </span>
                           </div>
                        </label>
                     </div>
                  ))}
               </div>
            </div>
            <div className='ecomerce-container-section-0 border border-1 px-0 pt-1 pb-5 border-inset'>
               {groups.map((group) => (
                  <div
                     key={group.group_id}
                     className="ecommerce-menu-card d-flex align-items-center bg-white rounded-3 
                     mt-1 shadow-sm mx-1 p-1 border position-relative"
                     onClick={() => (selectProduct(group))}
                  >
                     <div className="flex-shrink-0">
                        <img
                           src={group.img}
                           className="img-ecommerce-menu-card rounded-3"
                           alt={group.name}
                        />
                     </div>
                     <div className="flex-grow-1 ms-3">
                        <div className="mt-2 me-2">
                           <span className="mt-1 title-ecommerce-menu-card">{group.name}</span>
                           <div className='d-flex justify-content-between align-items-center'>

                              <span className="mt-1 d-block  value-ecommerce-menu-card">$ {parseFloat(group.price).toFixed(2)}</span>

                           </div>

                        </div>
                     </div>

                     <div className='position-absolute bottom-0 end-0 me-1'>
                        <i class="uil uil-plus-circle text-muted opacity-50"></i>
                     </div>
                  </div>
               ))}
            </div>

            <ModalProduct
               modalShow={modalShow}
               setModalShow={setModalShow}
               selectedGroup={selectedGroup}
               segmentsGroup={segmentsGroup}
            />

            <BarMenu shoppingcart={shoppingcart}></BarMenu>
         </div>
      </section>
   )
}
