import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import './header.css'
import Lenguage from '../Lenguage/Lenguage';
//Utils 
import { RenderIf } from '../utils/RenderIf';
export const Header = () => {

   const { business_code, branchoffice_code, machine_code_table } = useParams();
   const [businessCode, setbusinessCode] = useState(business_code);
   const [modalShow, setModalShow] = React.useState(false);
   let logo = localStorage.getItem('logo')
   let brachoffice_name = ''
   let codeTable=''

   if (localStorage.getItem("branchoffice") != "" && localStorage.getItem("branchoffice") != undefined) {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      brachoffice_name = branchoffice.name
   }

   if(localStorage.getItem('table') != undefined &&localStorage.getItem('table') != '' && localStorage.getItem('table') != null){
      let dataTable = JSON.parse(localStorage.getItem('table'))
      codeTable= dataTable.table_code
      
   }
   return (
      <>
         <header className='bg-header text-center fw-bold'>
            <div className='d-flex justify-content-between  align-items-center'>
               <div>
                  <img src={logo}
                     alt="Logo Bussiness" className='img-ecommerce-header rounded-3' />

               </div>

               <div className='d-flex justify-content-between gap-2'>
                  <NavLink
                     to={`/branch/${businessCode}/${codeTable}`}
                  // className={({ isActive }) => isActive ? 'btn active-link btn-menu jello-horizontal m-0' : 'btn no-active-link m-0 p-0'}
                  >
                     <button className='btn btn-header-ecommerce   btn-sm rounded-3'>
                        <i className="uil uil-store icon-header-ecommerce"></i>
                        {/* <span className=''> Sucursales  </span> */}
                     </button>
                  </NavLink>


                  {/** Notification button */}
                  <RenderIf isTrue={branchoffice_code !== undefined && machine_code_table !== undefined}>
                     <NavLink
                        to={`/notifications/${businessCode}/${branchoffice_code}/${machine_code_table}`}>
                        <button className='btn btn-header-ecommerce  btn-sm rounded-3'>
                           <i className="uil uil-bell icon-header-ecommerce"></i>
                        </button>
                     </NavLink>
                  </RenderIf>
               </div>
            </div>
{/* 
            <div className='d-flex justify-content-center px-1'>
               <small className='fw-bold  text-store-header ' >  <i className="uil uil-store" ></i>Sucursal: {brachoffice_name} </small>
            </div> */}
         </header>

         <div className='d-flex justify-content-center  bg-light px-1 shadow-sm'>
            <small className='fw-bold  text-store-header text-dark-emphasis' >  <i className="uil uil-store" ></i>: {brachoffice_name} </small>
         </div>
{/* 
         <Lenguage
            show={modalShow}
            onHide={() => setModalShow(false)}
         /> */}
      </>


   )
}
