import React from 'react'
import Modal from 'react-bootstrap/Modal';
const InfoOrdersModal = (props) => {
   return (
      <>

         <Modal
            {...props}
            size="lg"

            centered
         >

            <Modal.Body className='p-0'>

               <section className='ecomerce-container-product-0'>
                  <div className='ecomerce-container-1'>
                     <div className='position-relative'>

                        <div className=' p-2 w-100 d-flex justify-content-between align-items-center  '>
                           <button className='btn btn-header-ecommerce-product  btn-sm rounded-3' onClick={props.onHide}>
                              <i className="uil uil-angle-left-b icon-header-ecommerce"></i>
                           </button>
                           <h4 className='fw-bold text-center'>
                              <i class="uil uil-bill me-1"></i>
                              Orden # 892323
                           </h4>
                           {/* <button className='btn btn-header-ecommerce-product   btn-sm rounded-3'>
                              <i class="uil uil-info icon-header-ecommerce"></i>
                           </button> */}
                           <span className='px-3'></span>
                        </div>

                     </div>
                     <div className='p-1'>
                        <div className='bg-header-orders py-2 text-center'>

                           <h2>$ 19.00 </h2>
                           <small className='fw-light'>Total pagado </small>
                        </div>
                        <div className='bg-header-orders py-2 text-center'>

                           <h2> Visa </h2>
                           <small className='fw-light'>Método de pago  </small>
                        </div>
                     </div>

                     <div className='ecomerce-container-info mx-2'>
                        <h4 className='ecomerce-container-info-title fw-bold mt-2'>Productos</h4>

                     </div>

                     <div className='ecomerce-container-section-0 border border-1 border-primary bg-light pb-5 '>

                        <div className='my-1 mx-1 list-pre-orders-inputs-pays'>                    

                           <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-1 shadow-sm"
                              htmlFor='radio1'
                           >
                              <div className="d-flex align-items-center gap-3 py-1 rounded" >
                                 <img src=''
                                    alt="Product" width="80" height="80" className="rounded border flex-shrink-0 " />
                                 <div className="d-flex gap-2 justify-content-around py-2">
                                    <span className="mb-3 text-wrap">
                                       Product 1
                                    </span>
                                 </div>
                              </div>
                           </label>
                        </div>





                        {/* <div className='p-1'>
                        <div className='d-flex justify-content-between align-items-center'>
                           <span className='item-value-product'>
                              $ 18.00
                           </span>
                           <div className='d-flex justify-content-around gap-1 align-items-center'>
                              <span><i class="uil uil-minus-circle item-count-product"></i></span>
                              <span className=' item-count-product'>0</span>
                              <span><i class="uil uil-plus-circle item-count-product"></i></span>
                           </div>
                        </div>
                        <button className='btn btn-add-product w-100' onClick={props.onHide} > Agregar al carrito</button>
                      */}
                     </div>
                     <div className='py-2'></div>
                  </div>
               </section>
            </Modal.Body>


         </Modal>

      </>
   )
}

export default InfoOrdersModal