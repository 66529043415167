import Swal from "sweetalert2";


function LoadingAlert(title, message) {
    if(title === undefined || message === undefined){
        title = 'Cargando....'
        message = 'Cargando información....<br/> <br/>'
    }
    return (
        //We show a loading alert 
        Swal.fire({
            title: title,
            html: message,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: () => {
                Swal.showLoading();
            }
        })
    )
}

export default LoadingAlert