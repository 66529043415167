import React from 'react'

import { Modal } from 'react-bootstrap';

import loading from './assets/img/loading.gif'
//Component
import Header from "./components/Header"

function ModalLoader({ background, showModalLoader, setShowModalLoader }) {

    return (
        <>
            <Modal
                show={showModalLoader}
                fullscreen>
                <Modal.Body className='d-flex align-items-center' style={{ backgroundImage: `url(${background})` }} >
                    <section>
                        <div>
                            <Header
                                classContainer={"kiosk-categories-header scale-up-horizontal-left"}
                                classImg={"kiosk-header-img-categories"}>
                            </Header>
                            <div className='d-flex justify-content-center'>
                                <img src={loading} alt="Loader" className='img-loading-discount'/>
                            </div>

                            <h1 className='kiosk-delivery_title  text-center '>
                                La solicitud de descuento será atendida pronto.
                            </h1>
                        </div>
                    </section>
                </Modal.Body>

            </Modal>

        </>
    )
}

export default ModalLoader