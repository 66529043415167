
import { StatusMenu } from "../../api/StatusMenu";
import { AuthFetchAPI } from "../../api/Auth";
import { useState } from 'react';

async function initData() {

  
  let machine = "";
  let business = "";
  let branchoffice = "";
  let logo = ""
  let params = false
  let token = localStorage.getItem("x-access-machine-token")
  if (localStorage.getItem('logo') != null || localStorage.getItem('logo') != undefined || localStorage.getItem('logo') != "") {
    logo = localStorage.getItem('logo')
  }
  if (localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("x-access-machine-token") == undefined || localStorage.getItem("branchoffice") == "" || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("machine") == "" || localStorage.getItem("machine") == undefined) {

  } else {
    params = true
    branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    //setBranchoffice(JSON.parse(localStorage.getItem('branchoffice')))
    machine = JSON.parse(localStorage.getItem('machine'));
    business = JSON.parse(localStorage.getItem("business"));
  }
  let data = {}




  // get status change of the info to  menu
  const getStatusContentMenu = async (budinessParthner, branchofficeParthner, machineParthner) => {
    
    let business_id = 0
    let branchoffice_id_general = 0
    let machine_id = 0

    if (budinessParthner === undefined) {
      business_id = business.id
      branchoffice_id_general = branchoffice.id
      machine_id = machine.id
    } else {
      business_id = budinessParthner
      branchoffice_id_general = branchofficeParthner
      machine_id = machineParthner
    }

    console.log(branchoffice_id_general);


    if (localStorage.getItem('generalData') == '' || localStorage.getItem('generalData') == undefined || localStorage.getItem('generalData') == null) {
      await getDataGeneralSmartpay(business_id, branchoffice_id_general, machine_id, 1)
    } else {

      let generalData = JSON.parse(localStorage.getItem('generalData'))
      let filterData = generalData.find(({ branchoffice_id }) => branchoffice_id == branchoffice_id_general)

      if (filterData != undefined) {
        try {
          const response = await StatusMenu.getContentMenu(token, machine_id)
          console.log("Status menu:");
          if (response.data.data.content_status == true) {
            await getDataGeneralSmartpay(business_id, branchoffice_id_general, machine_id, 2)
          } else {
            await dataconsolidation(generalData)
          }
        } catch (err) {
          console.log(err);
          data = 'error'
        }
      } else {
        await getDataGeneralSmartpay(business_id, branchoffice_id_general, machine_id, 3)
      }

    }
  }

  //Function get the groups by machine
  const getDataGeneralSmartpay = async (business_id, branchoffice_id, machine_id, step) => {

    try {

      const response = await AuthFetchAPI.getMenuGenerate(token, machine_id, branchoffice_id, business_id)
      let data = []
      switch (step) {
        case 1:
          data = [{ "branchoffice_id": branchoffice_id, "data": response.data.data }]
          break;
        case 2:
          let generalData1 = JSON.parse(localStorage.getItem('generalData'))
          //let filterData = generalData1.filter(({ branchoffice_id }) => branchoffice_id != branchoffice_id);
          //filterData.data=response.data.data

          generalData1.forEach((dataNow) => {
            if (dataNow.branchoffice_id == branchoffice_id) {
              dataNow.data = response.data.data
            }
          })

          console.log(generalData1);
          data = generalData1

          break;
        case 3:
          let generalData2 = JSON.parse(localStorage.getItem('generalData'))
          generalData2.push({ "branchoffice_id": branchoffice_id, "data": response.data.data })
          data = generalData2

          break;

      }

      localStorage.setItem('generalData', JSON.stringify(data))
      dataconsolidation(data)
    } catch (err) {
      console.log(err);
      data = 'error'

    }
  }

  //consolidates data into corresponding statements
  const dataconsolidation = (generalData) => {
    let position = {}

    generalData.forEach((dataNow) => {
      if (dataNow.branchoffice_id == branchoffice.id) {
        position = dataNow.data
      }
    })
    data = position
  }

  await getStatusContentMenu();
  return data
}

export default initData