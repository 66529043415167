import React from 'react'

 
function UserType() {
   
    return (
        <>
     Not found 
        </>
    )
}

export default UserType