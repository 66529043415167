import React, { useState, useEffect } from 'react';



function Promotions({promotions, getSegmentsByPromotion }) {


   const [currentIndex, setCurrentIndex] = useState(0);

   const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % promotions.length);

   };

   const prevSlide = () => {
      setCurrentIndex((prevIndex) =>
         prevIndex === 0 ? promotions.length - 1 : prevIndex - 1
      );

   };


   useEffect(() => {
      const intervalId = setInterval(() => {
         setCurrentIndex((prevIndex) => (prevIndex + 1) % promotions.length);
      }, 8000);

      return () => clearInterval(intervalId);
   }, [promotions]);


   return (
      <>
         <div className="carousel-container">
            {promotions.map((promotion, index) => (
               <div
                  key={index}
                  className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
                  onClick={async () => getSegmentsByPromotion(promotion)}
               >
                  <img src={promotion.img} alt={`slide ${index}`} loading='lazy' />
               </div>
            ))}
         </div>
         <div className='position-absolute btn-direction w-100 d-flex justify-content-between'>

            {/* 1 */}
            <button className={`nav-button rounded-circle shadow position-relative`}
               onClick={prevSlide}
       
            >
               <i className="uil uil-angle-left-b"></i>

            </button>

            {/* 2 */}
            <button className={`nav-button rounded-circle shadow position-relative`}
   
               onClick={nextSlide}>
               <i className="uil uil-angle-right-b "></i>

            </button>
         </div>



      </>
   )
}

export default Promotions