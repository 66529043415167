import React, { useState } from 'react'
//modals
import Modalnotificator from './ModalNotificator';
import ModalDonLeePoints from './ModalDonLeePoints';
import ModalDonLeeRegister from './ModalDonLeeRegister';
//Component
import Header from "./components/Header"
import { Modal } from 'react-bootstrap';


function ModalDonleeKiosk({ showModalDonlee, setShowModalDonlee, icon, background, setLocator, setClientPhone, formatJsonOrder, nextStep, clientPhone, setAtivity }) {
   // const [displayNumber, setDisplayNumber] = useState('');
   // const [points, setPoints] = useState(false)
   const [showModalNotificator, setShowModalNotificator] = useState(false);
   const [showModalDonLeePoints, setShowModalDonLeePoints] = useState(false);
   const [showModalDonLeeRegister, setShowModalDonLeeRegister] = useState(false);
   let businesssConfig = {}

   if (localStorage.getItem !== undefined || localStorage.getItem !== null || localStorage.getItem !== "") {
      businesssConfig = JSON.parse(localStorage.getItem('businessConfig'));
   }

   //Focus
   const [isButtonFocusedId, setButtonFocusId] = useState(null);

   const handleButtonFocus = (buttonId) => {
      setButtonFocusId(buttonId);
   };

   const handleButtonBlur = () => {
      setButtonFocusId(null);
   };


   return (
      <>
         <Modal
            show={showModalDonlee}
            fullscreen
            onHide={() => setShowModalDonlee(false)}>
            <Modal.Body className='p-0 m-0'>
               <main   style={{ backgroundImage: `url(${background})` }}
               
               className='kiosk-delivery h-100 d-flex justify-content-center align-items-center' onClick={() => setAtivity(1)}>
                 <section className='m-3'>
                 <Header
                     classContainer={"kiosk-delivery_header m-0 p-0"}
                     classImg={"kiosk-delivery_header_img"}>
                  </Header> 
                  <div className='kiosk-container_section scale-up-top '>
                     <h2 className='kiosk-delivery_title  text-center '>
                        ¡Hola! <br />
                        ¿Tienes Paisanitos?
                     </h2>
                     <div className='kiosk-delivery-btn' >
                        <div className='kiosk-delivery_btns   mt-5 d-flex justify-content-center'>
                           <button
                              className={`btn  btn-outline-primary  btn-card-delivery btn-lg m-2 
                                        shadow border border-5 position-relative  `}
                              onFocus={() => handleButtonFocus('true')}
                              onBlur={handleButtonBlur}
                              onClick={() => setShowModalDonLeePoints(true)}
                           >
                              <div className='my-3'>
                                 <img src={businesssConfig.points_img} width='100px' alt='logo' />
                                 {/* <UilUtensils size="240" /> */}
                              </div>
                              {/* <i className="uil uil-utensils btn-img-delivery"></i>  */}
                              <span className='kiosk-text-card d-block '>
                                 Si tengo <br /> Paisanitos
                              </span>


                              <div className="position-absolute postition-icon-md">
                                 {isButtonFocusedId === 'true' && <img src={icon} alt="Hand" className='icon-focus' />}
                              </div>
                           </button>


                           <button
                              className={`btn  btn-outline-success   btn-card-delivery  btn-lg m-2 
                                          shadow border border-5 position-relative
                                          ${isButtonFocusedId === 'next' ? 'focused' : ''}`}
                              onFocus={() => handleButtonFocus('next')}
                              onBlur={handleButtonBlur}
                              onClick={() => setShowModalNotificator(true)}>

                              <div className='my-3'>
                                 <i className="uil uil-angle-double-right btn-img-delivery"></i>
                              </div>
                              {/* <i className="uil uil-utensils btn-img-delivery"></i>  */}
                              <span className='kiosk-text-card d-block ' >
                                 Continuar
                              </span>


                              <div className="position-absolute postition-icon-md">
                                 {isButtonFocusedId === 'next' && <img src={icon} alt="Hand" className='icon-focus' />}
                              </div>
                           </button>

                        </div>
                     </div>
                  </div>
                 </section>


               </main>
            </Modal.Body>
            <div className='position-absolute w-100 d-flex justify-content-between align-content-center align-items-center bottom-0 start-0'>
                  {/* 1 */}
                  <button
                     type="button"
                     onClick={() => setShowModalDonlee(false)}
                     className={`m-0 btn-lg  shadow-lg border-0 button-ft_1
                   button-ft_1 position-relative`}
                     title='Regresar'>
                     <svg xmlns="http://www.w3.org/2000/svg"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                        className=""
                        id="left-arrow">
                        <path
                           d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>
           
                  </button>       
               </div>
         </Modal>

         <Modalnotificator icon={icon} background={background} showModalNotificator={showModalNotificator} setShowModalNotificator={setShowModalNotificator} formatJsonOrder={formatJsonOrder} setLocator={setLocator} businesssConfig={businesssConfig} nextStep={nextStep} setAtivity={setAtivity}></Modalnotificator>
         <ModalDonLeePoints icon={icon} background={background} showModalDonleePoints={showModalDonLeePoints} setShowModalDonLeePoints={setShowModalDonLeePoints} setShowModalDonLeeRegister={setShowModalDonLeeRegister} formatJsonOrder={formatJsonOrder} setClientPhone={setClientPhone} businesssConfig={businesssConfig} nextStep={nextStep} setShowModalNotificator={setShowModalNotificator} setAtivity={setAtivity}></ModalDonLeePoints>
         <ModalDonLeeRegister icon={icon} background={background} showModalDonleeRegister={showModalDonLeeRegister} setShowModalDonLeeRegister={setShowModalDonLeeRegister} formatJsonOrder={formatJsonOrder} setClientPhone={setClientPhone} businesssConfig={businesssConfig} nextStep={nextStep} setShowModalNotificator={setShowModalNotificator} clientPhone={clientPhone} setAtivity={setAtivity}></ModalDonLeeRegister>

      </>
   )
}

export default ModalDonleeKiosk