import React, { useState } from 'react'
import { RequestLocalFetchAPI } from '../../api/PaymentPost/RequestLocal';
// import { YappyFetchAPI } from '../../api/PaymentPost/Yappy';
import StepYappy from './StepYappy';
import ModalDataClient from './ModalDataClient';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import ModalYappyQr from './ModalYappyQr';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import { PreOrderFetchAPI } from '../../api/PaymentPost/PreOrder';

//Component
import Header from "./components/Header"
import { MenuBar } from './components/MenuBar/MenuBar';
function Payments({ icon, categories, background, updateData, nextStep, prevStep, skipToStep3, paymentMethod, setPageLoader, paymentApproval,
  setPaymentApproval, paymentInfo, setPaymentInfo, shoppingCart, formatJsonOrder, setDetail_Payment_bank, setStep, registernewOrder,
  ruc, setRuc, name, setName, dv, setDv, setAtivity, setPriority,  invoiceTotal, invoiceTotalItbms, invoiceSubtotal, invoiceTotalDiscount, taxesArrayAll, preOrder, preOrderInvoice }) {

  console.log("pago", paymentMethod);

  const [showModalKeyCode, setShowModalKeyCode] = useState(false)
  const [showModalkeyDataClient, setShowModalkeyDataClient] = useState(false);
  const [statePayment, setStatePayment] = useState(false);
  const [modalYappyQr, setModalYappyQr] = useState(false);
  const [dataYappy, setDataYappy] = useState({})

   const updatePage = () => {
    if (localStorage.getItem('branchoffice_original') !== null) {
      localStorage.setItem('branchoffice', localStorage.getItem('branchoffice_original'))
    }

    if (localStorage.getItem('machine_original') !== null) {
      localStorage.setItem('machine', localStorage.getItem('machine_original'))
    }
    window.location.reload();
  }


  //function process payment of the order
  const processPayment = async (e, pays) => {
    e.preventDefault()
    setAtivity(2)
    setPaymentInfo(pays)
    formatJsonOrder(pays)

    let namePayment = pays.payment

    if (shoppingCart.length > 0) {
      setPriority(1)
      if (namePayment === 'Credito') {
        nextStep()

        //LoadingAlert(title, message)
        //registernewOrder(0)
        setPaymentApproval({ status: 1, description: "Registrando ..." });
        setTimeout(() => {
          setAtivity(3)

          registernewOrder()
        }, 3000);

      } else if (namePayment === 'Yappy') {


        //GenerateYappyTransaction()

        setShowModalKeyCode(true)
        //requestPyament(pays);
        //setShowModalPhone(true)
        //setChange(0);
      } else if (namePayment === "Mixto") {
        //setAmountPending((parseFloat(total).toFixed(2) - parseFloat(amountPaymentReady).toFixed(2)))
        //setShowModalMixto(true)
      }else if(namePayment === "Pagar en caja"){
        handleActionAsk()
      } else {
        nextStep();
        requestPyament(pays);


      }

    } else {
      //ErrorAlert("No hay productos en el carrito", "Agrega un producto", "info");
    }


  }

  //genrate yappy transaction
  // const GenerateYappyTransaction = async () => {

  //   let token = localStorage.getItem('x-access-machine-token');
  //   let machine = JSON.parse(localStorage.getItem('machine'));
  //   let dataOrder = JSON.parse(localStorage.getItem('Detail_order'))
  //   let data = {
  //     "machine_id": machine.id,
  //     "total": dataOrder.Total,
  //     "subtotal": dataOrder.subtotal,
  //     "tax": dataOrder.itbms,
  //     "discount": dataOrder.Descuento.toFixed(2),
  //     "transaction_code": dataOrder.transaction_code,
  //     "description": "Prueba"
  //   }
  //   try {
  //     const response = await YappyFetchAPI.newYappyQr(data, token)
  //     console.log(response);
  //     setDataYappy(response.data);


  //     getYappyTransaction()
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // const getYappyTransaction = async () => {
  //   let token = localStorage.getItem('x-access-machine-token');
  //   let yappy_transaction_id = dataYappy.body.transactionId
  //   try {
  //     const response = await YappyFetchAPI.getYappyTransaction(yappy_transaction_id, token);
  //     console.log(response);

  //     let status = response.body.status;
  //     setModalYappyQr(true)


  //     switch (status) {
  //       case 'PENDING':
  //         console.log("Transaccion pendiente");

  //         setTimeout(async () => {
  //           getYappyTransaction()
  //         }, 10000)

  //         break;

  //       case 'CONMPLETED':
  //         console.log("Transaccion aprobada");

  //         break;

  //       case 'DECLINED':
  //         setPaymentInfo({ payment: "", payment_id: 0 })
  //         console.log("Transaccion declinda");

  //         break;

  //       case 'EXPIRED':
  //         setPaymentInfo({ payment: "", payment_id: 0 })
  //         console.log("Transaccion expirada");

  //         break;

  //       case 'FAILED':
  //         setPaymentInfo({ payment: "", payment_id: 0 })
  //         console.log("Transaccion fallida");

  //         break;

  //       case 'VOIDED':
  //         setPaymentInfo({ payment: "", payment_id: 0 })
  //         console.log("Transaccion Eliminada");

  //         break;

  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }


  //function action request pyment local
  const requestPyament = async (pays, phoneYappy) => {

    if (statePayment === false) {
      setStatePayment(true)
      console.log("inicion la peticion");

      let dataOrder = JSON.parse(localStorage.getItem('Detail_order'))

      let JsonTr = {}
      if (pays.payment === "Yappy") {
        JsonTr = [{
          Tipo: 'SALE',
          Producto: 'COBRAR',
          Itbms: dataOrder.itbms,
          Descuento: dataOrder.Descuento.toFixed(2),
          Monto: dataOrder.Total,
          TipoVenta: pays.payment.toUpperCase(),
          paymentMethod: pays,
          orderid: dataOrder.transaction_code,
          tel: "507" + phoneYappy,
          total: dataOrder.Total,
          subtotal: dataOrder.subtotal,
          taxes: dataOrder.itbms,
        }];
      } else {

        let methodPayment = ""
        if (pays.payment === "MasterCard") {
          methodPayment = "MC"
        } else {
          methodPayment = pays.payment
        }

        JsonTr = [{
          Tipo: 'SALE',
          Producto: 'COBRAR',
          Itbms: dataOrder.itbms,
          Descuento: dataOrder.Descuento.toFixed(2),
          Monto: dataOrder.Total,
          TipoVenta: methodPayment.toUpperCase(),
          paymentMethod: pays,
          orderid: dataOrder.transaction_code,
        }];
      }

      setAtivity(2)
      try {
        const response = await RequestLocalFetchAPI.postPaymentMethod(JsonTr, 0);

        setDetail_Payment_bank(response.data.data.EMVStreamResponse)
        let responseCode = response.data.data.EMVStreamResponse.responseCode;
        let description = response.data.data.EMVStreamResponse.responseCodeDescription
        setStatePayment(false)

        if (paymentInfo.payment !== "Yappy") {
          formatJsonOrder(pays, response.data.data.EMVStreamResponse)
          if (responseCode === "00") {
            setPaymentApproval({ status: 1, description: description });
            //setAtivity(3)
          } else {
            setPaymentApproval({ status: 2, description: description });
            setAtivity(5);
            setPriority(0)
            console.log("entre aqui");
          }
        } else {

          if (responseCode === "20") {
            setPaymentApproval({ status: 1, description: description });
            //setAtivity(3)
          } else {
            setPaymentApproval({ status: 2, description: description });
            setAtivity(5)
            setPriority(0)

          }
        }

        setTimeout(() => {
          if (paymentInfo.payment !== "Yappy") {
            if (responseCode === "00") {
              setAtivity(3)
              registernewOrder();

            } else {
              setPaymentInfo({ payment: "", payment_id: 0 })
              setStep(6)
            }
          } else {
            if (responseCode === "20") {
              setAtivity(3)
              registernewOrder();

            } else {
              setPaymentInfo({ payment: "", payment_id: 0 })
              setStep(6)
            }
          }

          setPaymentApproval({ status: 0, description: "Procesando pago..." })
        }, 3000);


        //origin yappy selection

      } catch (err) {
        setPaymentInfo({ payment: "", payment_id: 0 })
        setStatePayment(false)
        ErrorAlert('', 'No se pudo procesar el pago', 'error')
        setStep(6)
        setAtivity(5)
        console.log(err);
      }

    } else {
      console.log("peticion en uso");
    }
  }

  const handleActionAsk = async () => {
    if (preOrderInvoice === true) {
      updatePreOrder()
    } else {
      createPreOrder()
    }
  }

   //create preOrder
   const createPreOrder = async () => {

    if (shoppingCart.length > 0) {
      LoadingAlert()
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'))
      let table = JSON.parse(localStorage.getItem('table'))

      console.log(shoppingCart);
      let json = {
        "client_name": table.name,
        "localizador": "00",   
        "client_phone": "0",
        "ruc": "0000",
        "dv": "00",
        "id_Client": 0,
        "dgi_qr": "0",
        "Orden": shoppingCart,
        "Descuento": invoiceTotalDiscount,
        "Total": invoiceTotal,
        "itbms": invoiceTotalItbms,
        "taxes": taxesArrayAll,
        "subtotal": invoiceSubtotal,
        "machine_id": machine.id,
        "branchoffice_id": branchoffice.id,
        "tipoOrden": "para llevar",
        "paymethod": 0,
        "is_discount_notification": false,
        "is_cupon": false,
        "is_devolution_notification": false,
        "is_pre_order": true, "user": {
          id: 0,
          name: "",
          code: 0
        },
        "table_name": table.name,
        "table_code": table.code,
        "table_id": table.id
      }

      try {
        const response = await PreOrderFetchAPI.createPreOrder(json, token)

        //printOrder(response.data.newTempOrder._id, response.data.newTempOrder.code)
        //setShow(false)
        let message = "Precuenta creada con exito."
        let title = "Operacion exitosa."
        let icon = "success"
        ErrorAlert(message, title, icon)
        setStep(9)
        //setPageLoader(1)
      } catch (err) {
        console.log(err);
      }
    } else {
      ErrorAlert("Debes agregar  algo al carrito", "No hay productos en el carrito", "info")
    }
  }

  // update pre order
  const updatePreOrder = async () => {
    let token = localStorage.getItem('x-access-machine-token');
    let table = JSON.parse(localStorage.getItem('table'))
    console.log(shoppingCart);
    let json = {
      "client_name": table.name,
      "localizador": "00",
      "ruc": "000",
      "dv": "000",
      "is_discount_notification": false,
      "_id": preOrder._id,
      "order_type": "para llevar",
      "groups": shoppingCart,
      "discount": invoiceTotalDiscount,
      "total": invoiceTotal,
      "tax": invoiceTotalItbms,
      "taxes": taxesArrayAll,
      "subtotal": invoiceSubtotal,
      "table_name": table.name,
      "table_code": table.code,
      "table_id": table.id
    }
    try {
      const response = await PreOrderFetchAPI.updateOrderTempPreOrder(json, token)
      console.log(response);
      let message = "Precuenta actulizada con exito."
      let title = "Operacion exitosa."
      let icon = "success"
      ErrorAlert(message, title, icon)
      setStep(9)


    } catch (err) {
      let message = ""
      let title = "Ocurrio un error"
      let icon = "error"
      ErrorAlert(message, title, icon)
    }
  }

  return (
    <>
      <section className='kiosk-delivery ' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(5)}>
        {/*invoice data client */}

        <div className='d-flex justify-content-center'>
          <Header
            classContainer={"kiosk-delivery_header scale-up-horizontal-left"}
            classImg={"kiosk-delivery_header_img"}>
          </Header>
        </div>
        <div>
          <h5 className='kiosk-subtitle-md scale-up-horizontal-left  '>Datos de factura </h5>
        </div>

        <div className="d-flex row justify-content-center alignt-items-center my-4">
          <div className="badge  bg-light text-dark   border-primary border rounded-4  position-relative text-wrap w-75">

            <span className='text-primary fw-bold d-block  p-0 text-uppercase kiosk-subtitle-md mt-2'>{name}  </span>
            <strong className='kiosk-subtitle-md d-block my-3'>
              RUC: {ruc} DV:{dv}
            </strong>


            {/* <div className='col-md-12'>
              <h3 className='kiosk-subtitle-md text-center'>{name}</h3>
              <p className='kiosk-subtitle-s text-center'>RUC: {ruc} DV:{dv}</p>
            </div> */}

            <div className=' position-absolute top-100 start-100 translate-middle'>
              <button className={`btn btn-primary rounded 
              btn-sm  my-2 kiosk-subtitle-md position-relative h-100`}
                onClick={() => setShowModalkeyDataClient(true)} >
                Editar

              </button>
            </div>
          </div>


        </div>


        <div>
          <h5 className='kiosk-subtitle-md  scale-up-horizontal-left '>Métodos de Pago </h5>
        </div>


        <div className='kiosk-container_section'>
          <div className="container-fluid mb-5 pb-5">
            <div className="radio-tile-group row pb-5">
              {paymentMethod.map((pays, index) => (
                <>
                  <div className='col-6 col-md-4 col-lg-4 mt-lg-5 mt-md-5  mt-sm-1 mt-2 '>
                    <button className={`input-container focus-pay position-relative position-relative`}
                      key={index} onClick={(e) => {setAtivity(2); setPaymentInfo(pays); processPayment(e, pays)}}>
                      {/* <input id="pays-id" className="radio-button " type="radio" name="radio" /> */}
                      <div className="radio-tile bg-brand-primary p-2">
                        <div className="d-block ">
                          {/* <i className="uil uil-transaction icon-pay"></i> */}
                          <img src={pays.img} alt="" className='img-pay-met-kk rounded-4 px-2' />
                        </div>
                        <label className='radio-tile-label fw-bold text-white' for="pays-id" >{pays.payment}</label>
                      </div>
                    </button>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        {/* <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>  
          <button
            type="button"
            onClick={prevStep}
            className="m-0 btn-lg  shadow-lg border-0 button-ft_1" title='Regresar' >
            <svg xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              className=""
              id="left-arrow">
              <path
                d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>
          </button>
          <div className="m-2 ">
            <button className='btn btn-danger kiosk-subtitle w-100 rounded-pill border px-5 py-3 h-100' onClick={() => setPageLoader(1)}>
    
              Cancelar </button>
          </div>
        </div> */}

        <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>
          <button
            onClick={prevStep}
            className={`btn btn-primary kiosk-subtitle-md  
          kiosk-border-4 py-3 m-3 w-100 position-relative  rounded-4`}
          >Regresar
          </button>
          <button
            onClick={() => {setPageLoader(1); updatePage()}}
            className={`btn btn-danger kiosk-subtitle-md  
          kiosk-border-4 py-3 m-3 w-100 position-relative rounded-4`}
          >   Cancelar orden
          </button>
        </div>

        {/* <div className="kiosk-delivery-footer ">
          <small>
            © 2023 Smart Pay
          </small>
          <small onClick={nextStep}>
            Powered By Smart Retail Group
          </small>
        </div> */}

        <StepYappy icon={icon} background={background} showModalKeyCode={showModalKeyCode} setShowModalKeyCode={setShowModalKeyCode} paymentInfo={paymentInfo} requestPyament={requestPyament} nextStep={nextStep} setAtivity={setAtivity} setPaymentInfo={setPaymentInfo} ></StepYappy>
        <ModalDataClient background={background} ruc={ruc} setRuc={setRuc} dv={dv} setDv={setDv} name={name} setName={setName} showModalkeyDataClient={showModalkeyDataClient} setShowModalkeyDataClient={setShowModalkeyDataClient} setAtivity={setAtivity}></ModalDataClient>
        <ModalYappyQr background={background} nextStep={nextStep} prevStep={prevStep} skipToStep3={skipToStep3} paymentInfo={paymentInfo} setAtivity={setAtivity} modalYappyQr={modalYappyQr} setModalYappyQr={setModalYappyQr} dataYappy={dataYappy} ></ModalYappyQr>
     
     
        <MenuBar setStep={setStep} ></MenuBar>

      </section >



    </>
  )
}

export default Payments