import React from 'react'
import { useNavigate } from 'react-router-dom';
//Component
import Header from "./components/Header"
//Imgs
import { Modal } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

//api
import { MachinesFetchAPI } from '../../api/Machines';

function ModalInfoMachine({ showModalInfoMachine, setShowModalInfoMachine, infoMachine, business, branchoffice }) {

    const navigate = useNavigate();

    //Function to logout of the payment post
    const signout = async () => {
        let machine_token = localStorage.getItem('x-access-machine-token');

        //We logout the machine 
        try {
            const response = await MachinesFetchAPI.updateMachineStatus(infoMachine.code, false, machine_token)
            console.log(response);
            //We delete the tokens 
            localStorage.setItem('x-access-machine-token', "");
            //We delete the machine and user info 
            localStorage.setItem('machine', "");
            localStorage.setItem('branchoffice', "");
            localStorage.setItem('business', "");
            localStorage.setItem('Detail_order', "")

            navigate(`/kiosk`)
        } catch (err) {
            console.log(err)
        }

    }

    return (
        <>

            <Modal
                show={showModalInfoMachine}
                fullscreen
                onHide={() => setShowModalInfoMachine(false)}>
                <Modal.Body>
                    <section className='kiosk-delivery'>
                        <Header classContainer={"kiosk-delivery-header"}
                            classImg={"kiosk-header-img-delivery"}
                        ></Header>
                        <div className='kiosk-delivery_section scale-up-top'>
                            <h2 className='kiosk-text-title  text-center '>
                                Informacion de maquina <br />{infoMachine.machine_name}
                            </h2>
                            <div className='m-5'>
                                <Form className='mt-5'>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label className='kiosk-subtitle-md'>
                                                    Negocio:
                                                </Form.Label>
                                                <div className='kiosk-subtitle-s'>
                                                    {business.name}
                                                </div>
                                            </Form.Group >
                                            <Form.Group className="mb-3" >
                                                <Form.Label className='kiosk-subtitle-md' >
                                                    Codigo de negocio:
                                                </Form.Label>
                                                <div className='kiosk-subtitle-s'>
                                                    {business.code}
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Label className='kiosk-subtitle-md'>
                                                    Sucursal:
                                                </Form.Label>
                                                <div className='kiosk-subtitle-s'>
                                                    {branchoffice.name}
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='kiosk-subtitle-md' >
                                                    Codigo de sucursal:
                                                </Form.Label>
                                                <div className='kiosk-subtitle-s'>
                                                    {branchoffice.code}
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col sm={6}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label className='kiosk-subtitle-md'>
                                                    Tipo de maquina:
                                                </Form.Label>
                                                <div className='kiosk-subtitle-s'>{infoMachine.name_type_machine}</div>


                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Label className='kiosk-subtitle-md'>
                                                    Codigo de maquina:
                                                </Form.Label>
                                                <div className='kiosk-subtitle-s'>
                                                    {infoMachine.code}
                                                </div>
                                            </Form.Group>

                                            <Form.Group className="mb-3" >
                                                <Form.Label className='kiosk-subtitle-md'>
                                                    Idioma:
                                                </Form.Label>
                                                <div className='kiosk-subtitle-s'>
                                                    {infoMachine.language.name}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className='col-12'><button type="button" className=" btn btn-lg btn-vending btn-vending-warning w-100" onClick={async () => signout()}>
                                    <i className="uil uil-signout"></i> Cerrar sesion  </button></div>
                            </div>
                        </div>

                    </section>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-start'>

                    <button className='btn btn-primary btn-vending-product mx-4 rounded' onClick={() => setShowModalInfoMachine(false)} >
                        <i className="uil uil-arrow-left" ></i>
                        Regresar
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )
} export default ModalInfoMachine